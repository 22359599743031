import React from 'react'
import Img from 'gatsby-image'
import { MDBRow, MDBCol } from 'mdbreact'
import { MDBAnimation } from 'mdbreact'
// import image from "../images/digital-avatar.png";

class OverlayCard extends React.Component {
  render() {
    return (
      <>
        <MDBRow className="overlay-box align-items-center px-0">
          <MDBCol lg="6" md="12" className="pl-lg-0">
            <MDBAnimation reveal type="fadeInUp">
              <div className="overlay-text">
                {/* <img
                  src={image}
                  className="img-fluid mt-2"
                  loading="lazy"
                  alt="UNICOM Digital logo"
                /> */}

                <Img
                  fixed={this.props.logo.childImageSharp.fixed}
                  alt="UNICOM Digital logo"
                  className="img-fluid mt-2"
                />

                <div
                  dangerouslySetInnerHTML={{ __html: this.props.cardtitle }}
                />
                <div
                  className="text-gray-dark divlink inner"
                  dangerouslySetInnerHTML={{ __html: this.props.cardtext }}
                />
              </div>
            </MDBAnimation>
          </MDBCol>
          <MDBCol lg="6" md="12" className="pt-5">
            <div
              className="divlink outer"
              dangerouslySetInnerHTML={{ __html: this.props.body }}
            />
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}

export default OverlayCard